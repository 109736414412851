<template>
  <v-card>
    <v-card-text class="text-center pt-7">
      {{ paymentTranslation.name }}
      <p class="font-weight-medium text-h6">
        {{ accountNumber }}
      </p>
      <v-img v-if="qrCode" max-width="200" class="mb-4 mx-auto" :src="qrCode" />
      <p>{{ instructions }}</p>
      <c-btn
        class="mb-5"
        color="primary"
        :label="$t('add_funds.download_invoice')"
        :loading="documentIsLoading"
        @click="fetchDocument()"
      />
      <c-text-field
        :id="seleniumIds.SELENIUM_TEST_PAYMENT_FORM_TRANSACTION_HASH_FIELD"
        v-model="transactionHash"
        :placeholder="$t('add_funds.transaction_hash')"
        :loading="updateIsLoading"
        :error-messages="errors['metadata.txid_hash']"
      />
      <c-btn
        color="primary"
        :label="$t('add_funds.confirm')"
        :disabled="!transactionHash"
        :loading="updateIsLoading"
        @click="updateInvoice(transactionHash)"
      />
    </v-card-text>
    <v-card-actions class="justify-end">
      <c-btn depressed :label="$t('main.close')" :loading="updateIsLoading" @click="updateInvoice(null)" />
    </v-card-actions>
  </v-card>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import invoicedRepository from '@/services/repositories/invoiced-repository.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'PaymentManualCard',
    components: {
      CBtn,
      CTextField
    },
    props: {
      invoice: {
        type: Object,
        required: true
      },
      paymentTranslation: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        updateIsLoading: false,
        documentIsLoading: false,
        transactionHash: '',
        errors: {},
        seleniumIds
      }
    },
    computed: {
      accountNumber() {
        return this.invoice.formParams.account_number
      },
      qrCode() {
        return this.invoice.formParams.qr_code
      },
      instructions() {
        return this.invoice.formParams.instructions
      }
    },
    methods: {
      async fetchDocument() {
        this.documentIsLoading = true
        try {
          await invoicedRepository.document(this.invoice.formParams.invoice_id)
        } catch (error) {
          handleErrors(error)
        }
        this.documentIsLoading = false
      },
      async updateInvoice(hashValue) {
        this.updateIsLoading = true
        const invoiceId = this.invoice.formParams.invoice_id
        const params = {
          metadata: {
            txid_hash: hashValue
          }
        }
        try {
          await invoicedRepository.update({ invoiceId, params })
          if (hashValue) {
            this.$showSuccessNotification(this.$t('add_funds.success'))
          }
          this.$emit('close-manual-dialog')
        } catch (error) {
          this.errors = handleErrors(error)
        }
        this.updateIsLoading = false
      }
    }
  }
</script>
