<template>
  <div
    :class="[
      'd-flex flex-wrap align-center rounded px-2 py-2',
      value ? 'border-secondary' : 'border-primary'
    ]"
  >
    <c-switch :input-value="value" class="mx-2 pt-0" @change="$emit('input', !!$event)">
      <template #label>
        <span class="text-subtitle-2 mr-3">
          {{ $t('main.add_funds.autopayment') }}
        </span>

        <c-info-btn is-tooltip>
          <template #tooltipText>
            {{ $t('main.add_funds.auto_payment_description') }}
          </template>
        </c-info-btn>
      </template>
    </c-switch>
    <div
      class="text-caption text-uppercase font-weight-bold primary white--text rounded my-2 py-1 px-2"
    >
      {{ $t('main.recommended') }}
    </div>
  </div>
</template>

<script>
  import CSwitch from '@clickadilla/components/ui/CSwitch.vue'
  import CInfoBtn from '@clickadilla/components/ui/CInfoBtn.vue'

  export default {
    name: 'AutoPaymentSwitch',
    components: {
      CSwitch,
      CInfoBtn
    },
    props: {
      value: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
