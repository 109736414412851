<template>
  <div>
    <v-progress-linear v-if="fetchIsLoading" indeterminate color="primary" />
    <template v-else>
      <div class="d-flex align-center">
        <span class="text-body-2 black--text font-weight-medium"> Your company name </span>
        <c-info-btn is-tooltip :text="$t('add_funds.wire_transfer_info')" class="ml-2" />
      </div>
      <v-row class="align-center pt-3">
        <v-col cols="8" sm="7" class="py-0">
          <v-card class="pa-3" flat color="secondary-lighten">
            {{ userNameOrCompanyName }}
          </v-card>
        </v-col>
        <v-col cols="4" sm="5" class="py-0">
          <c-btn
            depressed
            class="border-primary transparent"
            large
            block
            :to="{ name: 'Personal' }"
            :label="$t('add_funds.wire_change')"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CInfoBtn from '@clickadilla/components/ui/CInfoBtn.vue'

  export default {
    name: 'WireTransferField',
    components: {
      CBtn,
      CInfoBtn
    },
    computed: {
      ...mapGetters('personal', ['userNameOrCompanyName']),
      ...mapState('personal', ['fetchIsLoading'])
    },
    created() {
      this.fetchUserProfile()
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile'])
    }
  }
</script>
