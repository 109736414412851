<template>
  <div class="d-flex flex-column">
    <c-text-field
      :id="seleniumIds.SELENIUM_TEST_PAYMENT_FORM_PROMO_FIELD"
      v-model="couponCode"
      :label="$t('add_funds.promo_code')"
      label-bold
      :error-messages="errorMessages"
      :class="inputClasses"
      :height="50"
      outlined
      :loading="isLoading"
      @keydown.enter="$emit('update')"
    >
      <template #append>
        <button :disabled="!couponCode" type="button" @click="couponHandler()">
          <v-icon
            v-if="!isClearIconShown"
            size="18"
            :class="[
              !couponCode && !hasError ? 'secondary-darken--text opacity-40' : 'primary--text'
            ]"
          >
            $arrow-right-outlined
          </v-icon>
          <v-icon v-else class="black--text opacity-40" size="18">
            $close-outlined
          </v-icon>
        </button>
      </template>
    </c-text-field>

    <div v-show="totals.couponBonus !== 0" class="primary--text mx-auto">
      <span>{{ $t('add_funds.promo_bonus') }}:</span>
      {{ couponBonusText }}
    </div>
  </div>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'Promocodes',
    components: {
      CTextField
    },
    props: {
      value: {
        type: String,
        required: true
      },
      errorMessages: {
        type: Array,
        default: () => []
      },
      totals: {
        type: Object,
        default: () => ({})
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      hasError() {
        return this.errorMessages.length !== 0
      },
      inputClasses() {
        return {
          invalid: this.hasError,
          valid: this.couponIsValid
        }
      },
      couponBonusText() {
        return `$ ${toFixedByDefault(this.totals.couponBonus)} (${
          this.totals.coupon.bonusPercentage || 0
        }%)`
      },
      isClearIconShown() {
        return this.hasError || this.couponIsValid
      },
      couponIsValid() {
        return this.totals.couponBonus !== 0
      },
      couponCode: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('clear-error')
          this.$emit('input', value)
        }
      }
    },
    methods: {
      couponHandler() {
        if (this.hasError) {
          this.couponCode = ''
          return
        }

        if (this.couponIsValid) {
          this.couponCode = ''
          this.$emit('update')
          return
        }

        this.$emit('update')
      },
      toFixedByDefault
    }
  }
</script>

<style lang="scss" scoped>
  .invalid {
    &::v-deep {
      input {
        color: var(--v-error-base);
      }
    }
  }

  .valid {
    &::v-deep {
      input {
        color: var(--v-primary-base);
      }
    }
  }
</style>
