<template>
  <div>
    <div class="d-flex title align-center justify-space-between flex-wrap mt-6 mb-5">
      {{ $t('add_funds.title') }}
      <c-btn
        color="primary"
        :to="{ name: 'Verifications', params: { type: 'card' } }"
        :label="$t('add_funds.verify_card')"
        class="white--text"
      />
    </div>

    <v-card elevation="0">
      <v-card-title class="title white--text font-weight-medium black pa-3 pl-6">
        {{ $t('add_funds.select_payment') }}
      </v-card-title>

      <v-card-text class="px-4 px-sm-8 pb-8 pt-5">
        <payment-methods class="mb-3" />
        <v-alert text color="info" class="text-body-2 mt-7">
          {{ $t('add_funds.alert_payment1', { name: adNetwork.name }) }}
          <div class="mt-4">
            {{ $t('add_funds.alert_payment2') }}
          </div>
        </v-alert>
        <div v-if="adNetwork.settings.enablePanvyDetails" class="d-flex flex-wrap flex-column align-start align-md-center flex-md-row mt-5">
          <v-img src="@/assets/add-funds/visa-mastercard.svg" max-width="62" max-height="17" contain class="d-block mr-4 pt-1 mb-0 mb-md-6" />
          <img :src="panvy" alt="image" class="d-block mt-2 mt-md-0 pt-1 mb-6" />
          <v-spacer />
          <div v-if="helpUrls.terms_and_conditions" class="d-flex flex-nowrap align-center mb-6">
            <v-icon size="16" color="info" class="mr-2">
              $information
            </v-icon>
            <a
              :href="helpUrls.terms_and_conditions"
              target="_blank"
              class="d-block text-no-wrap info--text"
            >
              {{ $t('add_funds.accepting_conditions') }}
            </a>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import PaymentMethods from '@/views/AddFunds/PaymentMethods/Index.vue'

  export default {
    name: 'AddFunds',
    components: {
      PaymentMethods,
      CBtn
    },
    computed: {
      ...mapState('settings', ['adNetwork', 'locale']),
      ...mapGetters('settings', ['helpUrls']),
      panvy() {
        const imagesByBreakpoint = {
          en: {
            mobile: require('@/assets/add-funds/mobile/panvy-en.svg'),
            tablet: require('@/assets/add-funds/tablet/panvy-en.svg'),
            desktop: require('@/assets/add-funds/desktop/panvy-en.svg')
          },
          ru: {
            mobile: require('@/assets/add-funds/mobile/panvy-ru.svg'),
            tablet: require('@/assets/add-funds/tablet/panvy-ru.svg'),
            desktop: require('@/assets/add-funds/desktop/panvy-ru.svg')
          },
          es: {
            mobile: require('@/assets/add-funds/mobile/panvy-es.svg'),
            tablet: require('@/assets/add-funds/tablet/panvy-es.svg'),
            desktop: require('@/assets/add-funds/desktop/panvy-es.svg')
          },
          de: {
            mobile: require('@/assets/add-funds/mobile/panvy-de.svg'),
            tablet: require('@/assets/add-funds/tablet/panvy-de.svg'),
            desktop: require('@/assets/add-funds/desktop/panvy-de.svg')
          },
          jp: {
            mobile: require('@/assets/add-funds/mobile/panvy-en.svg'),
            tablet: require('@/assets/add-funds/tablet/panvy-en.svg'),
            desktop: require('@/assets/add-funds/desktop/panvy-en.svg')
          }
        }[this.locale]

        if (this.$vuetify.breakpoint.name === 'xs') {
          return imagesByBreakpoint.mobile
        }
        if (this.$vuetify.breakpoint.name === 'sm') {
          return imagesByBreakpoint.tablet
        }
        return imagesByBreakpoint.desktop
      }
    },
    created() {
      this.fetchUserProfile()
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile'])
    }
  }
</script>
