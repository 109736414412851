<template>
  <div class="d-flex flex-column">
    <div v-if="totals.fee !== 0">
      <span class="secondary-darken--text">{{ $t('add_funds.fee_title') }}:</span>
      $ {{ toFixedByDefault(totals.fee) }} ({{ toFixedByDefault(totals.feePercent) || 0 }}%)
    </div>

    <div v-if="totals.bonus">
      <span class="secondary-darken--text">{{ $t('add_funds.bonus') }}:</span>
      $ {{ toFixedByDefault(totals.bonus) }} ({{
        totals.bonusPercent ? totals.bonusPercent * 100 : 0
      }}%)
    </div>
  </div>
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'

  export default {
    name: 'Totals',
    props: {
      totals: {
        type: Object,
        required: true
      }
    },
    methods: {
      toFixedByDefault
    }
  }
</script>
